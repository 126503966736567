import { Controller } from "stimulus"
import Swal from 'sweetalert2'
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  connect(){
    this.element.addEventListener("click", this.handleClick.bind(this));
  }
  
  disconnect() {
    this.element.removeEventListener("click", this.handleClick);
  }

  async handleClick(event) {
    event.preventDefault();
    this.beforeAjax();

    const url = this.element.href
    const request = new FetchRequest("POST", url, {
      body: JSON.stringify({}),
      contentType: "application/json",
    });

    try {
      const response = await request.perform();
      if (response.ok) {
        const data = await response.json;
        this.handleAjax(data);
      } else {
        this.showError();
      }
    } catch (error) {
      this.showError();
    }
  }

  beforeAjax() {
    let isAdmin = this.data.get('isAdminValue') === "true"
    let text = isAdmin
      ? "Your report is generating, and you will receive an email notification when it is complete. You may now exit this screen."
      : "Generating your report...";
    Swal.fire({
      title: "Processing!",
      text: text,
      showConfirmButton: isAdmin,
    });
    Swal.showLoading();
  }

  handleAjax(data) {
    const subscription = Cable.subscriptions.create({ channel: "DownloadsChannel", download_id: data.download_id }, {
      received: (data) => {
        Swal.hideLoading();
        if (data.error) {
          this.showError();
        } else {
          Swal.fire({
            title: "Finished!",
            text: "Your download is ready",
            showConfirmButton: true,
            confirmButtonText: "Download",
          }).then(() => {
            if (typeof Trestle != 'undefined') {
              Turbo.visit(data.url)
            } else {
              Turbolinks.visit(data.url)
            }
          });
        }
        subscription.unsubscribe();
      },
    });
  }

  showError() {
    Swal.hideLoading();
    Swal.fire({
      title: "Error!",
      text: "There was an issue generating your download.",
      showConfirmButton: true,
    });
  }
}