/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

window.jQuery = window.$ = $

import Rails from "@rails/ujs"
Rails.start()
import Turbolinks from "turbolinks"
Turbolinks.start()
require("jquery")
require("@nathanvda/cocoon")

import ActionCable from 'actioncable'
ActionCable.startDebugging()
window.Cable = ActionCable.createConsumer()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import 'trix'
import 'trix/dist/trix.css'

import "styles/iris.scss"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import "bootstrap_confirm_modal"
import "badge_filter"
import "password_requirements"
import "jquery-ui"
import "iris.coffee"
