import {Modal} from "bootstrap"
import Rails from "@rails/ujs"

const defaultConfirmMethod = Rails.confirm

// Handle confirm events with Bootstrap modal
document.addEventListener('confirm', function(event) {  
  const element = event.target;

  // If the element has already been confirmed, we don't need to show the modal
  // We reset the confirmation, override Rail's default confirm to short circuit the confirmation
  // and allow Rail's confirm to proceed as planned.
  if (element.confirmed) {
    element.confirmed = false
    Rails.confirm = () => true
    return
  }

  event.preventDefault();

  const message = element.dataset.confirm || "Are you sure you want to do this?"
  
  // Create modal elements
  const modalHtml = `
    <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmModalLabel">Are you sure?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ${message}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" id="confirmBtn">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  `;

  // Add modal to document
  const modalElement = document.createElement('div');
  modalElement.innerHTML = modalHtml;
  document.body.appendChild(modalElement);

  // Initialize Bootstrap modal
  const modal = new Modal(document.getElementById('confirmModal'));
  
  // Handle confirm button click
  document.getElementById('confirmBtn').addEventListener('click', () => {
    modal.hide();
    modalElement.remove();
    element.confirmed = true
    element.click()
  });

  // Handle modal dismiss
  document.getElementById('confirmModal').addEventListener('hidden.bs.modal', () => {
    modalElement.remove();
  });

  // Show the modal
  modal.show();
});

document.addEventListener('confirm:complete', function(event) {
  Rails.confirm = defaultConfirmMethod
})