import {sameHeights} from "equal_heights"

$(->

  ready(document)

  if window.MutationObserver
    observer = new MutationObserver((mutations) ->
      mutations.forEach((mutation) ->
        Array.prototype.forEach.call(mutation.addedNodes, (node) ->
          ready(node)
        )
      )
    )

    mutationConfig = { childList: true, subtree: true, attributes: false }
    observer.observe(document, mutationConfig)
  else
    document.addEventListener("DOMNodeInserted", (ev) ->
      setTimeout(->
        ready(ev.srcElement)
      , 0)
    )
)

$(document).on "turbolinks:load", ->
  Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach((tooltip) ->
    new bootstrap.Tooltip(tooltip)
  )

  Array.from(document.querySelectorAll("[data-auto-fire]")).forEach((el) ->
    el.click()
  )

ready = (el) ->
  $('#menu-toggle', el).on 'click', (e) ->
    $('#wrapper').toggleClass 'toggled'
    e.preventDefault()
    return

  $('[data-slider]', el).each ->
    initializeSlider(this)

  $("[data-donut-chart]", el).each ->
    initializeDonutChart(this)

  $("[data-equal-height]", el).each ->
    selector = $(this).data('equal-height')
    elements = $(this).find(selector)
    setTimeout(->
      sameHeights(elements)
    , 100)


    resizeHandler = (e) ->
      sameHeights(elements)

    $(window).on 'resize', resizeHandler
    # Remove the event handler when we navigate away
    $(document).one 'turbolinks:load', (e) ->
      $(window).off 'resize', resizeHandler

  $("[data-badge-filter]", el).each ->
    new BadgeFilter(this)

  $("[data-password-field]", el).each ->
    PasswordRequirements.configure(this)

  $("[data-password-confirmation-field]", el).each ->
    PasswordConfirmation.configure(this)

checkCapacityStatusTime = ->
  $statusWidget = $('#statusWidget')
  if $statusWidget.length > 0
    setTimeout(checkCapacityStatusTime, 10000)
    return

  $capacityStatus = $('#capacity-status-widget')
  if $capacityStatus.length == 0
    setTimeout(checkCapacityStatusTime, 10000)
    return

  organizationModal = $("#requiredOrganizationQuestions")
  if (organizationModal.hasClass("show"))
    setTimeout(checkCapacityStatusTime, 10000)
    return

  data = $capacityStatus.data()
  nextDate = data.nextCapacityStatusTime
  promptForNewStatus = data.promptForNewStatus != undefined
  if promptForNewStatus
    if nextDate
      date = new Date(nextDate)
      if date < new Date() # If the date is in the past
        $capacityStatus.find('a').trigger('click') # Trigger the capacity status box!
    else
      $capacityStatus.find('a').trigger('click')

  setTimeout(checkCapacityStatusTime, 10000)

checkCapacityStatusTime() # Kick things off

checkRequiredOrganizationQuestions = ->
  organizationModal = $("#requiredOrganizationQuestions")

  if (organizationModal.hasClass("show"))
    setTimeout(checkRequiredOrganizationQuestions, 5000)
    return
  else
    organizationEditPathRegex = /^\/organizations\/([^?\/]+)\/edit/
    match = window.location.pathname.match(organizationEditPathRegex)
    if !match
      $.ajax(
        method: "GET",
        url: "/organizations/check_required_questions"
      )

  setTimeout(checkRequiredOrganizationQuestions, 5000)

checkRequiredOrganizationQuestions()


window.initializeSlider = (el) ->
  $this = $(el)

  disable = $this.data('disable') == ''
  min = $this.data('min')
  max = $this.data('max')
  value = $this.data('value')
  fieldSelector = $this.data('field')
  $field = if fieldSelector
    $(fieldSelector)
  else
    $()

  valueToColor = (value) ->
    red = [255,0,0]
    yellow = [255,255,0]
    green = [0,255,0]

    [value, color1, color2] = if value <= 50
      [value, green, yellow]
    else
      [value - 50, yellow, red]

    value *= 2 # Scale from 0 to 100
    value /= 100 # Turn to percentage
    color = interpolateColors(color1,color2,value)

    return "rgb(#{color[0]}, #{color[1]}, #{color[2]})"

  interpolateColors = (color1,color2,factor) ->
    result = []
    range = [0..2]
    for index of range
      c1 = color1[index]
      c2 = color2[index]
      difference = c2 - c1
      scaledComponent = Math.round(factor * difference)
      result[index] = c1 + scaledComponent
    result

  handleSlide = (event, ui) ->
    value = ui.value

    if value < 10
      event.preventDefault()
      return

    updateRangeColor(value)

  updateRangeColor = (value) ->
    color = valueToColor(value)
    widget = $this.slider('widget')
    range = widget.find('.ui-slider-range')[0]
    range.style.backgroundColor = color

  $this.slider({
    min: min,
    max: max,
    disabled: disable,
    value: value,
    range: "min",
    slide: handleSlide,
    change: (event, ui) ->
      updateRangeColor(ui.value)
      $field.val(ui.value)

  })

  $field.val(value)
  updateRangeColor(value)

window.initializeDonutChart = (el) ->
  percentage = parseInt($(el).data('donut-chart'))
  svgns = "http://www.w3.org/2000/svg"
  chart = document.createElementNS(svgns, "svg:svg")
  chart.setAttribute("width", "100%")
  chart.setAttribute("height", "100%")
  chart.setAttribute("viewBox", "0 0 100 100")

  circleRadius = 47
  ringBackgroundColor = '#CBCBCC'
  ringFillColor = "#A5548E"
  ringWidth = 3

  cx = 50
  cy = 50

  circumference = 2 * Math.PI * circleRadius

  ring = document.createElementNS(svgns, "circle")
  ring.setAttribute("cx", cx)
  ring.setAttribute("cy", cy)
  ring.setAttribute("r", circleRadius)
  ring.setAttribute("fill", 'transparent')
  ring.setAttribute("stroke", ringBackgroundColor)
  ring.setAttribute("stroke-width", ringWidth)
  chart.appendChild(ring)

  segment = document.createElementNS(svgns, "circle")
  segment.setAttribute("cx", cx)
  segment.setAttribute("cy", cy)
  segment.setAttribute("r", circleRadius)
  segment.setAttribute("fill", "transparent")
  segment.setAttribute("stroke", ringFillColor)
  segment.setAttribute("stroke-width", ringWidth)
  filledAmount = circumference * (percentage / 100)
  segments = [filledAmount, circumference - filledAmount].join(' ')
  segment.setAttribute("stroke-dasharray", segments)
  segment.setAttribute("stroke-dashoffset", circumference * .25)
  chart.appendChild(segment)

  text = document.createElementNS(svgns, "text")
  text.textContent = percentage + "%"
  text.setAttribute('text-anchor', 'middle')
  text.setAttribute('dominant-baseline', 'middle')
  text.setAttribute('x', cx)
  text.setAttribute('y', cy)
  chart.appendChild(text)

  el.removeChild(node) for node in el.children

  el.appendChild(chart)