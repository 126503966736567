(function() {

  var passwordRegex = /(?=.{8})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*\(\)_\+\-=\/<>\?\[\]{}|])/

  function isValidPassword(password) {
    return password.match(passwordRegex)
  }

  window.PasswordRequirements = {
    configure: function(el) {
      var passwordRequirements = [
        "Minimum of 8 characters",
        "At least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol",
        "Not match any of your last 10 passwords"
      ]

      var $el = $(el)
      var hasBeenFocused = false

      var helpEl = $('<div class="form-text"></div>').css({
        display: "none"
      })
      $('<span>Requirements</span>').appendTo(helpEl)
      var passwordRequirementsList = $("<ul />").appendTo(helpEl)
      passwordRequirements.forEach(function(requirement) {
        $("<li>" + requirement + "</li>").appendTo(passwordRequirementsList)
      })

      helpEl.insertAfter(el)

      var duration = 300

      var checkingPassword = false

      $el.on('focus', function() {
        hasBeenFocused = true
        helpEl.slideDown(duration)
        updateFieldState($el.val())
      })

      $el.on('blur', function() {
        helpEl.slideUp(duration)
        checkingPassword = true
        updateFieldState($el.val())
      })

      $el.on('input', function() {
        updateFieldState($el.val())
      })

      $el.parents('form').on('submit', function(e) {
        if (!hasBeenFocused) { return }
        checkingPassword = true
        updateFieldState($el.val())
      })

      function updateFieldState(password) {
        if (!checkingPassword) { return }

        var $group = $el.parents('.form-group')

        if (isValidPassword(password)) {
          $el.data('valid', true)
          $group.addClass('has-success')
          $group.removeClass('has-danger')
        } else {
          $el.data('valid', false)
          $group.addClass('has-danger')
          $group.removeClass('has-success')
        }
      }
    }
  }

  window.PasswordConfirmation = {
    configure: function(el) {
      var $el = $(el)
      var $group = $el.parents('.form-group')
      var $equalTo = $($el.data('password-confirmation-field'))

      $el.on('input', updateState)
      $equalTo.on('input', updateState)

      function updateState() {
        var elVal = $el.val()
        var equalToVal = $equalTo.val()

        if ($equalTo.data('valid') == false) {
          $group.removeClass('has-success')
          return
        }

        if (elVal == equalToVal && equalToVal != '') {
          $group.addClass('has-success')
          $group.removeClass('has-danger') // If we are already editing and have failed then the has danger class will be there
        } else {
          $group.removeClass('has-success')
        }
      }
    }
  }
})()