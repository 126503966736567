(function(global) {

  global.BadgeFilter = function(el) {
    var $el = $(el)
    this.$el = $el
    this.itemSelector = $el.data('items')
    this.itemDataName = $el.data('item-data')

    this.$el.on('click', function(e) {
      var $target = $(e.target)
      if ($target.hasClass('badge')) {
        // Toggle selected class
        $target.toggleClass('selected')

        this.updateState()
      }
    }.bind(this))
  }

  global.BadgeFilter.prototype.updateState = function() {
    var selectedFilters = this.$el.find('.badge.selected').toArray().map(function(el) {
      return el.innerText
    })

    var items = $(this.itemSelector)
    var showItems = []

    // When the user has selected nothing then we show all the organizations
    if (selectedFilters.length == 0) {
      showItems = items.toArray()
    } else {
      var itemDataName = this.itemDataName
      var itemData = items.toArray().map(function(el) { return $(el).data(itemDataName) })  

      itemData.forEach(function(data, index) {
        for (var i = selectedFilters.length - 1; i >= 0; i--) {
          var filter = selectedFilters[i]
          if (data.indexOf(filter) == -1) {
            return
          }
        }

        showItems.push(items[index])
      })
    }

    var hideItems = []
    items.toArray().forEach(function(el) {
      if (showItems.indexOf(el) == -1) {
        hideItems.push(el)
      }
    })

    showItems.forEach(function(el) {
      $(el).fadeIn(500)
    })

    hideItems.forEach(function(el) {
      $(el).fadeOut(500)
    })
  }

})(window)