import { Controller } from "stimulus"

import "select2"
import "select2/dist/css/select2.css"

export default class extends Controller {
  connect() {
    let $this = $(this.element)
    let minimumInputLength = parseInt($this.data('min-input')) || 0
    let isTagging = $this.data('select2-tagging') != undefined
    let allowsClear = $this.data('allows-clear') != undefined
    let ensureLastUser = $this.data('organization-ids-with-only-one-user-left') != undefined
    let width = $this.data('width') || 'resolve'
    $this.select2({
      placeholder: $this.data('placeholder'),
      allowClear: allowsClear,
      minimumInputLength: minimumInputLength,
      tags: isTagging,
      width: width,
      dropdownParent: this.element.closest('[data-defines-presentation-context]'),
    })

    if (ensureLastUser){
      $this.on('select2:unselecting', function (e) {
        if($this.data('organization-ids-with-only-one-user-left').includes(parseInt(e.params.args.data.id))) {
          e.preventDefault();
          dataConfirmModal.confirm({
            text: $this.data('last-organization-message'),
            commit: "Deactivate User",
            onConfirm: function() {
              $(e.params.args.data.element).prop("selected", false)
              $(e.currentTarget).trigger("change.select2")
            },
          })
        }
      });
    }
  }

  disconnect() {
    $(this.element).select2('destroy')
  }
}